import React, { createContext, useState, useEffect, useContext } from "react";
import { TStoreProps, TStoreUploadData } from "./Store.types";
import AuthContext from "../../../../store/auth-context";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import TabView from "../../common/TabView/TabView";
import playIcon from "../../../../assets/images/play-white.svg";
import powerIcon from "../../../../assets/images/power-white.svg";
import trashWIcon from "../../../../assets/images/trash-full-white.svg";
import DataTable from "react-data-table-component";
import zipIconWhite from "../../../../assets/images/zip.svg";
import downloadIcon from "../../../../assets/images/download_grey.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";
import UploadModal from "./UploadModal/UploadModal";
import DeleteModal from "../../common/DeleteModal/DeleteModal";
import { toast } from "react-toastify";
import axios from "axios";
import pLimit from 'p-limit';
import JSZip, { forEach } from "jszip";
import {
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";

export const UploadModalContext = createContext({
  uploadModal: false,
  mode: "",
  datasetType: "",
  setUploadModal: (state: boolean) => {},
  handleUploadData: (data: TStoreUploadData) => {},
});

const Store = (props: TStoreProps) => {
  //==================================
  //STATE
  //===================================
  const company = localStorage.getItem('company')
  const [deleting, setDeleting] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [successUploadModal, setSuccessUploadModal] = useState(false);
  const [successDeleteModal, setSuccessDeleteModal] = useState(false);
  const [mode, setMode] = useState("Audio");
  const [datasetType, setDataSetType] = useState("Input");
  const [dataOptions, setDataOptions] = useState({
    INPUT: [],
    NOISE: [],
    FALSE_ALARM: [],
    HIT_RATE: [],
    DETECTIONS: [],
    WEIGHTS: [],
    NOISE_AMBIENT: [],
    NOISE_IMPULSIVE: []
  });
  const [deleteKey, setDeleteKey] = useState("");
  const [progress, setProgress] = useState(-1);

  //==================================
  //HOOKS
  //===================================
  const getDataOptionsApi = useApi(dataSourcesApi.getDataSource);
  const uploadDataApi = useApi(dataSourcesApi.postUploadData);
  const deleteDataApi = useApi(dataSourcesApi.deleteDatasource);
  const getPresignedApi = useApi(dataSourcesApi.getPresignedUrl);

  useEffect(() => {
    getDataOptionsApi.request(fetchAwsDirectoryPaths());
  }, [mode]);

  useEffect(() => {
    //set data options
    if (
      getDataOptionsApi.loading === false &&
      !getDataOptionsApi.error &&
      getDataOptionsApi.data
    ) {
      setDataOptions(getDataOptionsApi.data);
      setDataSetType("Input")
    }
  }, [
    getDataOptionsApi.loading,
    getDataOptionsApi.error,
    getDataOptionsApi.data,
  ]);

  useEffect(() => {
    //set data options
    if (uploadDataApi.loading === false && uploadDataApi.error) {
      toast.error(
        "An unexpected error occured. Please check your data format and try again."
      );
      uploadDataApi.clearError();
    }
  }, [uploadDataApi.loading, uploadDataApi.error, uploadDataApi.data]);

  useEffect(() => {
    //set data options
    if (
      getPresignedApi.loading === false &&
      !getPresignedApi.error &&
      getPresignedApi.data
    ) {
      
    }
  }, [getPresignedApi.loading, getPresignedApi.error, getPresignedApi.data]);

  //==================================
  //HANDLERS
  //===================================

  const handleFolderSelection = (event: React.ChangeEvent<HTMLInputElement>) =>{
    const files = event.target.files;
    if (!files){
      return
    }

  }

  const fetchPathName = (fileName: string) =>{
    let directory = "";
    if (mode == 'Sensors'){
      directory = '_Sensors'
    }else if(mode == 'Denoiser'){
      directory = '_Denoiser'
    }
    return `${datasetType}${directory}/${fileName}`;
  }

  const fetchAwsDirectoryPaths = () =>{
    switch(mode){
      case 'Sensors':
        return 'aws_s3_directories_sensors'
      case 'Denoiser':
        return 'aws_s3_directories_denoiser'
      default:
        return 'aws_s3_directories_all'
    }
  }

  const extractClassNames = async (file: any) => {
    try {
      const zip = new JSZip();
      const data = await zip.loadAsync(file);
      const topLevelDirectories: Set<string> = new Set();
      let containsWavFiles = false;
      const subDirectories: Set<string> = new Set();

      // First pass: Identify top-level directories and check for .wav files
      data.forEach((relativePath, zipEntry) => {
        const parts = relativePath.split("/");
        if (parts.length === 2) {
          if (zipEntry.dir) {
            topLevelDirectories.add(parts[0]);
          } else if (relativePath.endsWith(".wav")) {
            containsWavFiles = true;
          }
        }
      });

      // If there's a single top-level directory
      if (topLevelDirectories.size === 1) {
        const topLevelDir = Array.from(topLevelDirectories)[0] + "/";

        // If it contains .wav files, it's a class
        if (containsWavFiles) {
          return [topLevelDir.replace(/\/$/, "")];
        } else {
          // If it contains other subdirectories, add them to the list
          data.forEach((relativePath, zipEntry) => {
            if (
              zipEntry.dir &&
              relativePath.startsWith(topLevelDir) &&
              relativePath !== topLevelDir
            ) {
              subDirectories.add(
                relativePath.replace(topLevelDir, "").split("/")[0]
              );
            }
          });
        }
      } else {
        // If there are multiple top-level directories, treat each as a class
        topLevelDirectories.forEach((dir) => subDirectories.add(dir));
      }
      return Array.from(subDirectories);
    } catch (err) {
      return [];
    }
  };

  //RETRY function
const uploadPartWithRetry: any = async(s3Client: S3Client, uploadParams: any, maxRetries = 5, retryDelay = 5000) => {
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
          // Attempt to upload the part
          const response = await s3Client.send(new UploadPartCommand(uploadParams));
          return {
              ETag: response.ETag,
              PartNumber: uploadParams.PartNumber,
          };
      } catch (error) {
          console.error(`Attempt ${attempt} to upload part ${uploadParams.PartNumber} failed`, error);
          if (attempt < maxRetries) {
              // Wait for retryDelay before retrying
              await new Promise(resolve => setTimeout(resolve, retryDelay));
          } else {
              // Re-throw the error if all attempts fail
              throw new Error(`Failed to upload part ${uploadParams.PartNumber} after ${maxRetries} attempts`);
          }
      }
  }
}

//FUnction to upload a single file
const uploadFile = async(file: File, key: string, newProgress?: number) => {
  if (typeof process.env.REACT_APP_AWS_ACCESS_KEY_ID !== 'string' || typeof process.env.REACT_APP_AWS_SECRET_ACCESS_KEY !== 'string') {
    throw new Error("AWS credentials must be provided!");
  }

  const s3Client = new S3Client({ 
    region: 'us-east-2',
    credentials: {
      accessKeyId:process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    },
  });

  const bucketName = "aoncluster-bucket";

  const maxRetries = 3;
  const retryDelay = 5000;
  try{
    for (let attempt = 1; attempt <= maxRetries; attempt++){
      try{
        const result = await s3Client.send(new PutObjectCommand({
          Bucket: bucketName,
          Key: key,
          Body: file
        }));
        if (newProgress && newProgress > progress){
          setProgress(newProgress);
        }
        return;
      }catch(err){
        if (attempt < maxRetries){
          // Wait for retryDelay before retrying
          await new Promise(resolve => setTimeout(resolve, retryDelay));
        }else{
          // Re-throw the error if all attempts fail
          throw new Error(`Failed to upload after ${maxRetries} attempts`);
        }
      }
  
    }
  
  }catch(err){
    toast.error("An unexpected error occured while uploading your data.")
  }
}

// Function to upload a file using multipart upload
const uploadMultipartFile = async (file: File, key: string, fileUpload: boolean) => {
  const concurrencyLimit = 8;

  if (typeof process.env.REACT_APP_AWS_ACCESS_KEY_ID !== 'string' || typeof process.env.REACT_APP_AWS_SECRET_ACCESS_KEY !== 'string') {
    throw new Error("AWS credentials must be provided!");
  }
  
  const s3Client = new S3Client({ 
    region: 'us-east-2',
    credentials: {
      accessKeyId:process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    },
  });

  const bucketName = "aoncluster-bucket";

  let uploadId;

  try {
    // Start the multipart upload
    const multipartUpload = await s3Client.send(
      new CreateMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
      }),
    );

    uploadId = multipartUpload.UploadId;

    let uploadPromises = [];
    let uploadParts = [];
    let partsUploaded = 0;
    const partSize = 25 * 1024 * 1024; // 5 MB, adjust as needed
    const numberOfParts = Math.ceil(file.size / partSize);

    // Upload each part
    for (let i = 0; i < numberOfParts; i++) {
      const partNumber = i + 1;
      const start = i * partSize;
      const end = Math.min(start + partSize, file.size);

      const blobPart = file.slice(start, end);

      // Add the upload promise to the array
      uploadPromises.push(uploadPartWithRetry(s3Client, {
        Bucket: bucketName,
        Key: key,
        UploadId: uploadId,
        PartNumber: partNumber,
        Body: blobPart,
      }));

      if (uploadPromises.length == concurrencyLimit || i == numberOfParts - 1){
        uploadParts.push(...await Promise.all(uploadPromises));
        partsUploaded += uploadPromises.length;
        if (fileUpload)  setProgress(Math.floor((partsUploaded / numberOfParts) * 100));
        uploadPromises = [];
      }


    }

    // // Wait for all uploads to finish
    // const uploadParts = await Promise.all(uploadPromises);
    // Complete the multipart upload
    return await s3Client.send(
      new CompleteMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
        UploadId: uploadId,
        MultipartUpload: { Parts: uploadParts },
      }),
    );
  } catch (err) {
    console.error(err);
    // If there's an error, abort the upload
    if (uploadId) {
      await s3Client.send(
        new AbortMultipartUploadCommand({
          Bucket: bucketName,
          Key: key,
          UploadId: uploadId,
        }),
      );
    }
  }
};

const handleUploadData = async(data: TStoreUploadData) => {
  const { file, files, fileName } = data;
  if (fileName.includes(" ")){
    toast.error("Filename can not contain any spaces. Please replace all spaces with an underscore.");
    return;
  }
  setUploadModal(false);
  setProgress(0);
  const key = `${company}/${fetchPathName(fileName)}`;
  files.length > 0 ? handleUploadFolder(files, key) : handleUploadFile(file, fileName, key);
}

const handleUploadFolder = async(files: Array<any>, key: string) =>{
  const limit = pLimit(10);
  const twentyFiveMb = 25 * 1024 * 1024;
  let underFivePromises = [];
  let overFiveFiles = [];
  try{
    for (let i = 0; i < files.length; i++){
        const file = files[i];
        const fileKey = `${key}/${file.name}`;
        const newProgress = Math.floor((i / files.length) * 100)
        if (file.size > twentyFiveMb){
          await uploadMultipartFile(file, fileKey, false);
          setProgress(newProgress);
        }else{
          underFivePromises.push(limit(() => uploadFile(file, fileKey, newProgress)));
        }
    }
    await Promise.all(underFivePromises);
  }catch(err){
    toast.error("An unexpected error occured while uploading your data.")
  }
   setProgress(-1);
   toast.success("Data succesfully uploaded");
   getDataOptionsApi.request(fetchAwsDirectoryPaths());
}

const handleUploadFile = async(file: any, fileName: string, key: string) => {
  const startTime = performance.now()
  let url = "";
  let class_names = "";
  let num_classes = "";

  if (datasetType == "Input") {
    const folders = await extractClassNames(file);
    if (folders.length > 0 && folders.length < 50){
      class_names = folders.join();
    }else if (folders.length > 50){
      class_names = 'Speakers'
    }
    num_classes = folders.length.toString();
  }

  try{
    await uploadMultipartFile(file, key, true);
    setProgress(-1);
    toast.success("Data succesfully uploaded");
    const endTime = performance.now();
    getDataOptionsApi.request(fetchAwsDirectoryPaths());
  }catch(err){
    toast.error("An unexpected error occured while uploading your data.")
  }
   
}

  // const handleUploadData = async (data: TStoreUploadData) => {
  //   const startTime = performance.now();
  //   const { file, fileName, fileType } = data;
  //   if (fileName.includes(" ")) {
  //     toast.error(
  //       "Filename can not contain any spaces. Please replace all spaces with an underscore."
  //     );
  //   } else {
  //     setUploadModal(false);
  //     setProgress(0);
  //     const path = fetchPathName(fileName);
  //     let url = "";
  //     let class_names = "";
  //     let num_classes = "";

  //     if (datasetType == "Input") {
  //       const folders = await extractClassNames(file);
  //       if (folders.length > 0 && folders.length < 50){
  //         class_names = folders.join();
  //       }else if (folders.length > 50){
  //         class_names = 'Speakers'
  //       }
  //       num_classes = folders.length.toString();
  //     }
  //     console.log(class_names)

  //     try {
  //       // Get the pre-signed URL from your server
  //       const response = await fetch(
  //         "https://api.aondevices.com/api/data-source/presigned-url",
  //         {
  //           method: "POST",
  //           body: JSON.stringify({
  //             path,
  //             type: "put_object",
  //             class_names,
  //             num_classes,
  //           }),
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + localStorage.getItem("token"),
  //           },
  //         }
  //       );
  //       url = await response.json();
  //     } catch (err) {
  //       toast.error(
  //         "An unexpected error occured while uploading your data. Please try again."
  //       );
  //       setProgress(-1);
  //     }

  //     //Upload the file to S3
  //     try {
  //       setDeleteModal(false);
  //       setProgress(0);
  //       const headers: any = { "Content-Type": "application/zip" };
  //       if (datasetType == "Input" && class_names) {
  //         headers["x-amz-meta-class_names"] = class_names;
  //         headers["x-amz-meta-num_classes"] = num_classes;
  //       }
  //       const result = await axios.put(url, file, {
  //         headers: headers,
  //         onUploadProgress: (progressEvent) => {
  //           let percentCompleted = Math.round(
  //             //@ts-ignore
  //             (progressEvent.loaded * 100) / progressEvent.total
  //           );
  //           setProgress(percentCompleted);
  //         },
  //       });

  //       getDataOptionsApi.request(fetchAwsDirectoryPaths());
  //       setProgress(-1);
  //       toast.success("Data succesfully uploaded");
  //       const endTime = performance.now();
  //       console.log(`myFunction took ${endTime - startTime} milliseconds`);
  //     } catch (err) {
  //       toast.error(
  //         "An unexpected error occured while uploading your data. Please try again."
  //       );
  //       setProgress(-1);
  //     }
  //   }
  // };

  // const handleUploadData = (data: TStoreUploadData) => {
  //   const { file, fileName } = data;
  //   const path = `${datasetType}/${fileName}`
  //   getPresignedApi.request({ path })
  // }

  const deleteData = async () => {
    const path = fetchPathName(deleteKey)
    let url = "";
    try {
      setDeleteModal(false);
      setDeleting(true);
      // Get the pre-signed URL from your server
      const response = await fetch(
        "https://api.aondevices.com/api/data-source/presigned-url",
        {
          method: "POST",
          body: JSON.stringify({ path, type: "delete_object" }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      url = await response.json();
    } catch (err) {
      setDeleting(false);
      toast.error(
        "An unexpected error occured while deleting your data. Please try again."
      );
    }

    try {
      const result = await axios.delete(url);
      getDataOptionsApi.request(fetchAwsDirectoryPaths());
      toast.success("Data succesfully deleted");
    } catch (err) {
      toast.error(
        "An unexpected error occured while deleting your data. Please try again."
      );
    } finally {
      setDeleting(false);
    }
  };

  const deleteDataa = () => {
    deleteDataApi.request({ name: deleteKey, path: datasetType });
    setDeleteModal(false);
    setTimeout(() => setSuccessDeleteModal(true), 1000);
    removeDataSetFromList(deleteKey, datasetType);
    setDeleteKey("");
  };

  const removeDataSetFromList = (name: string, path: string) => {
    path = path.toUpperCase();
    //@ts-ignore
    let temp: Array<string> = [...dataOptions[path]];
    let index = 0;
    temp.map((item, idx) => {
      //@ts-ignore
      if (item.name == name) index = idx;
    });
    temp.splice(index, 1);
    setDataOptions({ ...dataOptions, [path]: temp });
  };

  const handleOnSelectedRowsChange = (selectedRows: Array<any>) => {
    if (selectedRows.length > 0) {
      setDeleteKey(selectedRows[0].name);
    } else {
      setDeleteKey("");
    }
  };

  const openUploadModal = (event: React.FormEvent) => {
    event.preventDefault();
    setUploadModal(true);
  };

  const contextValue = {
    uploadModal: uploadModal,
    mode: mode,
    datasetType: datasetType,
    setUploadModal: setUploadModal,
    handleUploadData: handleUploadData,
  };

  const columns = [
    {
      name: "Name",
      selector: (row: any) => row.name,
    },
    {
      name: "Last Modified",
      selector: (row: any) => row.modified,
    },
    // {
    //     name: 'Download',
    //     selector: (row: any) => row.download,
    // },
  ];

  //<a href="#" className="downloadIconLink"><img src={downloadIcon} className="tblDwnlIcon" alt="icon" /></a>

  const renderTable = (data: Array<any>) => {
    return (
      <DataTable
        columns={columns}
        data={data}
        customStyles={DTStyles}
        selectableRows={true}
        selectableRowsSingle
        selectableRowsHighlight
        onSelectedRowsChange={({ selectedRows }) =>
          handleOnSelectedRowsChange(selectedRows)
        }
      />
    );
  };

  const headers = [
    {
      title: "Input",
    },
    {
      title: "Noise",
    },
    {
      title: "Hit Rate",
    },
    {
      title: "False Alarm",
    },
    {
      title: "Detections",
    },
    {
      title: "Weights",
    },
  ];

  const body = [
    <div> {renderTable(dataOptions.INPUT)}</div>,
    <div> {renderTable(dataOptions.NOISE)}</div>,
    <div> {renderTable(dataOptions.HIT_RATE)}</div>,
    <div> {renderTable(dataOptions.FALSE_ALARM)}</div>,
    <div> {renderTable(dataOptions.DETECTIONS)}</div>,
    <div> {renderTable(dataOptions.WEIGHTS)}</div>,
  ];

  const renderTables = () => {
    const { INPUT, NOISE, NOISE_AMBIENT, NOISE_IMPULSIVE, HIT_RATE, FALSE_ALARM, DETECTIONS, WEIGHTS } =
      dataOptions;
    let dataset: Array<string> = [];
    switch (datasetType) {
      case "Input":
        dataset = INPUT;
        break;
      case "Noise":
        dataset = NOISE;
        break;
      case "Noise_Ambient":
        dataset = NOISE_AMBIENT;
        break;
      case "Noise_Impulsive":
        dataset = NOISE_IMPULSIVE;
        break;
      case "Hit_Rate":
        dataset = HIT_RATE;
        break;
      case "False_Alarm":
        dataset = FALSE_ALARM;
        break;
      case "Detections":
        dataset = DETECTIONS;
        break;
      case "Weights":
        dataset = WEIGHTS;
        break;
      default:
        dataset = [];
    }

    return <div> {renderTable(dataset)}</div>;
  };

  if (!getDataOptionsApi.loading) {
    return (
      <UploadModalContext.Provider value={contextValue}>
        <div>
          {progress >= 0 && (
            <div style={{ marginBottom: 20 }} className="globalCard">
              <h2 style={{ marginBottom: 0 }} className="sectionHeading">
                Uploading Data
              </h2>
              <h5 style={{ color: "#777", marginTop: 5 }}>
                Your data is currently uploading. Please do not close or refresh
                the page, doing so will stop your progress.
              </h5>
              <div className="progress-bar">
                <div
                  className="filler"
                  style={{
                    width: `${progress}%`,
                    fontWeight: 700,
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  {progress}%
                </div>
              </div>
            </div>
          )}

          {deleting && (
            <div style={{ marginBottom: 20 }} className="globalCard">
              <h2 style={{ marginBottom: 0 }} className="sectionHeading">
                Delting Data
              </h2>
              <h5 style={{ color: "#777", marginTop: 5 }}>
                Your data is currently deleting. Please do not close or refresh
                the page, doing so will stop your progress.
              </h5>
              <div className="loading" style={{ height: 20 }}>
                <div className="loading-spinner"></div>
              </div>
            </div>
          )}

          {progress < 0 && !deleting && (
            <div className="globalCard">
              <div className="sectionHead">
                <div className="flex-align-center">
                  <h2 className="sectionHeading">Mode : </h2>
                  <select
                    onChange={(e) => {
                      setMode(e.target.value);
                      setDeleteKey("");
                    }}
                    className="customSelect m-l-10"
                    name="sessionName"
                    id=""
                  >
                    <option value={"Audio"} selected={mode === "Audio"}>
                      Audio
                    </option>
                    <option value={"Sensors"} selected={mode === "Sensors"}>
                      Sensors
                    </option>
                    <option value={"Denoiser"} selected={mode === "Denoiser"}>
                      Denoiser
                    </option>
                    {/*<option value={"Weights"} selected={datasetType === "Weights"}>Weights</option>*/}
                  </select>

                  <h2 className="sectionHeading m-l-10">Dataset : </h2>
                  <select
                    onChange={(e) => {
                      setDataSetType(e.target.value);
                      setDeleteKey("");
                    }}
                    className="customSelect m-l-10"
                    name="sessionName"
                    id=""
                  >
                  
                    <option 
                      value={"Input"} 
                      selected={datasetType === "Input"}>
                      Input
                    </option>
                    
                    {mode == "Audio"  && (
                      <option
                        value={"Noise"}
                        selected={datasetType === "Noise"}
                      >
                        Noise
                      </option>
                    )}
                    {mode == "Denoiser"  && (
                      <option
                        value={"Noise_Ambient"}
                        selected={datasetType === "Noise_Ambient"}
                      >
                        Noise_Ambient
                      </option>
                    )}
                    {mode == "Denoiser"  && (
                      <option
                        value={"Noise_Impulsive"}
                        selected={datasetType === "Noise_Impulsive"}
                      >
                        Noise_Impulsive
                      </option>
                    )}
                    {mode !== 'Denoiser' && (
                      <option
                      value={"Hit_Rate"}
                      selected={datasetType === "Hit_Rate"}
                    >
                      Hit Rate
                    </option>
                    )}
                    {mode !== 'Denoiser' && (
                      <option
                        value={"False_Alarm"}
                        selected={datasetType === "False_Alarm"}
                      >
                        False Alarm
                      </option>
                    )}
                    
                    <option
                      value={"Detections"}
                      selected={datasetType === "Detections"}
                    >
                      Detections
                    </option>
                    {/*<option value={"Weights"} selected={datasetType === "Weights"}>Weights</option>*/}
                  </select>
                </div>
                <div className="btnHolder flex-align-center">
                  <a onClick={openUploadModal} className="btnCustom btnYellow">
                    + Upload Data
                  </a>
                  {deleteKey && (
                    <a
                      onClick={() => setDeleteModal(true)}
                      className="btnCustom btnRed"
                    >
                      {" "}
                      Delete Data
                    </a>
                  )}
                </div>
              </div>
              {renderTables()}
              {uploadModal && <UploadModal path={datasetType} />}
              {deleteModal && (
                <DeleteModal
                  title="Dataset"
                  subtitle="Are you sure you want to delete this dataset?"
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteData}
                />
              )}
              {successUploadModal && (
                <DeleteModal
                  title="Uploading Data"
                  subtitle="Your data is currently uploading. We will send you an email notification once it has completed uploading."
                  onDelete={() => setSuccessUploadModal(false)}
                />
              )}
              {successDeleteModal && (
                <DeleteModal
                  title="Deletion Started"
                  subtitle="Your data is currently deleting. We will send you an email notification once it has completed deleting."
                  onDelete={() => setSuccessDeleteModal(false)}
                />
              )}
            </div>
          )}
        </div>
      </UploadModalContext.Provider>
    );
  } else {
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }
};

export default Store;
